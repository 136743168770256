import React, { useContext, useEffect, useState } from 'react'
import { get, put, post, del } from "../../helper/fetch";
import { DrawerContext } from "../../context/DrawerContext";
import { toast } from 'react-toastify';
import { Cleaner } from "../../settings/bookingTypes";
import CleanerReviewCard from "../../components/CleanerReviewCard/CleanerReviewCard";
import CleanerMap from "../../components/CleanerMap/CleanerMap";
import AreaMapPicker from '../AreaMapPicker';
import { QuillEditor } from '../../components/QuillEditor';
import "quill/dist/quill.snow.css";
import dayjs from "dayjs";

type Review = Cleaner['listing']['reviews'][number];

type YearsExperienceDisplay = {
  startDate: string;
  yearsOfExperience: number;
};

type Tag = {
  id: number;
  category_id: number | null;
  name: string;
  description: string | null;
  client_visible: boolean;
  cleaner_visible: boolean;
  admin_visible: boolean;
  is_active: boolean;
  created_at: string;
};

const calculateExperience = (cleaningSince: string): YearsExperienceDisplay => {
  const startDate = dayjs(cleaningSince);
  const now = dayjs();
  const yearsOfExperience = now.diff(startDate, "year");

  return {
    startDate: 'From ' + startDate.format("YYYY"),
    yearsOfExperience
  };
};

const formatPhoneNumber = (value) => {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, "");

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early
  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
}


export default function CleanerProfile() {
  const [cleaner, setCleaner] = useState({} as Cleaner);
  const [editReviews, setEditReviews] = useState(false);
  const { state, dispatch } = useContext(DrawerContext);
  const reviewsRef = React.useRef(null);
  const [reviewsPage, setReviewsPage] = useState(1);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [isEditingYOE, setIsEditingYOE] = useState(false);
  const [tempYOE, setTempYOE] = useState<string>();
  const [isEditingPrice, setIsEditingPrice] = useState(false);
  const [tempPrice, setTempPrice] = useState<number>();
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const [tempPhone, setTempPhone] = useState<string>("");
  const [tags, setTags] = useState<Tag[]>([]);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    get<Cleaner>(`/me/listing`)
      .then(r => {
        setCleaner(r.data);
        setIsLoading(false);
      })
      .catch(e => {
        // Handle error
        setIsLoading(false);
      });

    // Fetch initial tags and selected tags
    Promise.all([fetchTags(), fetchSelectedTags()]).catch((error) => {
      console.error("Failed to fetch initial data:", error);
    });
  }, []);


  function getPaginatedReviews(reviews: Review[], pageNumber: number): Review[] {
    const pageSize = 5;
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    return reviews.slice(startIndex, endIndex);
  }

  const updateCleaner = async (data) => {
    const resp = await put(`/me/listing`, data);
    if (resp.status !== 200) {
      toast.error('could not update your info')
    } else {
      toast.success('updated your info')
      //refetch
      get<Cleaner>(`/me/listing`)
        .then(r => {
          setCleaner(r.data);
        }).catch(e => {
        })
      dispatch({ type: "CLOSE_DRAWER" })
    }
  }

  async function updateCleanerMap(newCoords) {
    const resp = await put(`/me/map`, newCoords);
    if (resp.status !== 200) {
      toast.error('could not update map')
    } else {
      toast.success('updated map')
    }
  }

  const handleSaveDescription = async (content: { text: string, html: string, delta: string }) => {
    try {
      await updateCleaner({
        ...cleaner,
        listing: {
          ...cleaner.listing,
          description: content.text,
          description_html: content.html,
          description_delta: content.delta
        }
      });
      setIsEditingDescription(false);
    } catch (error) {
      toast.error("Failed to save description");
    }
  };

  const handleSaveYOE = async () => {
    try {
      await updateCleaner({
        ...cleaner,
        listing: {
          ...cleaner.listing,
          cleaning_since: tempYOE
        }
      });
      setIsEditingYOE(false);
    } catch (error) {
      toast.error("Failed to update cleaning start date");
    }
  };

  const handleSavePrice = async () => {
    try {
      if (
        tempPrice &&
        cleaner.listing?.tier?.min_price &&
        cleaner.listing?.tier?.max_price &&
        (tempPrice < cleaner.listing.tier.min_price ||
          tempPrice > cleaner.listing.tier.max_price)
      ) {
        toast.error(`Price must be between $${cleaner.listing.tier.min_price} and $${cleaner.listing.tier.max_price}`);
        return;
      }

      await updateCleaner({
        ...cleaner,
        listing: {
          ...cleaner.listing,
          price: tempPrice
        }
      });
      setIsEditingPrice(false);
    } catch (error) {
      toast.error("Failed to update price");
    }
  };

  const handleSavePhone = async () => {
    try {
      await updateCleaner({
        ...cleaner,
        phoneNumber: tempPhone
      });
      setIsEditingPhone(false);
    } catch (error) {
      toast.error("Failed to update phone number");
    }
  };

  const fetchTags = async () => {
    try {
      const response = await get<{ data: Tag[] }>("/tags");
      const visibleTags = response.data?.data?.filter(
        (tag: Tag) => tag.is_active && tag.cleaner_visible
      );
      setTags(visibleTags);
    } catch (error) {
      console.error("Failed to fetch tags:", error);
      toast.error("Failed to load tags");
    }
  };

  const fetchSelectedTags = async () => {
    try {
      const response = await get<{ data: Tag[] }>("/tags/listings/tags");
      setSelectedTags(response.data.data);
    } catch (error) {
      console.error("Failed to fetch selected tags:", error);
      toast.error("Failed to load selected tags");
    }
  };

  const handleAddTag = async (tagId: number) => {
    try {
      // Find the tag that's being added
      const tagToAdd = tags.find(tag => tag.id === tagId);
      if (!tagToAdd) {
        throw new Error("Tag not found");
      }

      // Optimistically update UI
      setSelectedTags([...selectedTags, tagToAdd]);

      const response = await post("/tags/listings/tags", {
        tagIds: [tagId]
      });

      if (response.status === 201 || response.status === 200) {
        // Refetch both tags and selected tags to ensure data consistency
        await Promise.all([fetchTags(), fetchSelectedTags()]);
        toast.success("Tag added successfully");
      }
    } catch (error) {
      // Revert optimistic update on error
      const tagToAdd = tags.find(tag => tag.id === tagId);
      if (tagToAdd) {
        setSelectedTags(selectedTags.filter(tag => tag.id !== tagId));
      }
      console.error("Failed to add tag:", error);
      toast.error("Failed to add tag");
    }
  };

  const handleRemoveTag = async (tagId: number) => {
    try {
      const response = await del(`/tags/listings/tags/${tagId}`);

      if (response.status === 200) {
        // Refetch both tags and selected tags to ensure data consistency
        await Promise.all([fetchTags(), fetchSelectedTags()]);
        toast.success("Tag removed successfully");
      }
    } catch (error) {
      console.error("Failed to remove tag:", error);
      toast.error("Failed to remove tag");
    }
  };

  const craftProfileLink = () => {
    if (!cleaner?.given_name || !cleaner?.family_name) return "";
    let firstName;
    const fnameSplits = cleaner.given_name.split(' ');
    if (fnameSplits.length > 1) {
      firstName = fnameSplits[0];
    } else {
      firstName = cleaner.given_name;
    }
    let lnameSplits = cleaner.family_name.split(' ');
    let lastName;
    if (lnameSplits.length > 1) {
      lastName = lnameSplits[0];
    } else {
      lastName = cleaner.family_name;
    }

    // Create URL once at the top of the component
    const cleanerProfileUrl = `https://app.homespritz.ca/cleaner/${firstName.toLowerCase()}-${lastName[0].toLowerCase()}-${cleaner.listing?.id}`;
    return cleanerProfileUrl;
  }

  return (
    <div className="relative min-h-screen bg-gray-100">
      <main className="py-10">
        <div
          className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
          <div className="flex items-center space-x-5">
            <div className="flex-shrink-0">
              <div className="relative">
                <img
                  className="h-16 w-16 rounded-full"
                  src={`https://homespritz-cleaner-avatars.s3.ca-central-1.amazonaws.com/${cleaner.listing?.id}.jpg`}
                  alt=""
                />
                <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
              </div>
            </div>
            <div>
              <h1 className="text-2xl font-bold text-gray-900">{cleaner.name}</h1>
              <h2 className="text-1xl font-bold text-green-900">
                {isEditingPrice ? (
                  <div className="inline-flex items-center gap-2">
                    <span>$</span>
                    <input
                      type="number"
                      value={tempPrice}
                      onChange={(e) => setTempPrice(Number(e.target.value))}
                      className="w-20 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      min={cleaner.listing?.tier?.min_price}
                      max={cleaner.listing?.tier?.max_price}
                    />
                    <button
                      onClick={handleSavePrice}
                      className="inline-flex items-center px-2 py-1 text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                    >
                      Save
                    </button>
                    <button
                      onClick={() => setIsEditingPrice(false)}
                      className="inline-flex items-center px-2 py-1 text-sm font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200"
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <span className="inline-flex items-center gap-2">
                    ${cleaner.listing?.price}
                    <button
                      onClick={() => {
                        setIsEditingPrice(true);
                        setTempPrice(cleaner.listing?.price);
                      }}
                      className="ml-2 text-sm text-blue-600 hover:text-blue-800"
                    >
                      Edit
                    </button>
                  </span>
                )}
              </h2>
              {cleaner.listing?.tier && cleaner.listing?.tier?.id &&
                <div className='flex flex-col text-sm text-gray-600' >
                  <p>Your current tier is: <span className='font-semibold'> {cleaner.listing?.tier.name ? cleaner.listing?.tier.name : cleaner.listing?.tier.id}</span> </p>
                  <p>which means you pricing needs to be set in this range: <span className='font-semibold'> ${cleaner.listing?.tier.min_price} - ${cleaner.listing?.tier.max_price}</span></p>
                </div>
              }
              {
                cleaner.listing?.open
                  ? <h2 className="text-1xl font-bold text-green-600">Open for bookings!</h2>
                  : <h2 className="text-1xl font-bold text-red-700"> Closed to booking requests</h2>
              }
            </div>
          </div>
        </div>

        <div
          className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            {/* Description list*/}
            <section aria-labelledby="applicant-information-title">
              <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                    Display name to clients: {cleaner.listing?.displayName}
                  </h2>
                  <div className="pt-2 text-md text-gray-700 flex items-center gap-2">
                    <span>Your sharable direct booking link:</span>
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(craftProfileLink());
                        toast.success('Link copied to clipboard');
                      }}
                      className="inline-flex items-center px-3 py-1 text-sm font-medium rounded-md text-white bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
                    >
                      Copy!
                    </button>
                    <p className='text-sm text-gray-500'>{craftProfileLink()}</p>
                  </div>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Cleaning Since
                        <button
                          onClick={() => {
                            setIsEditingYOE(!isEditingYOE);
                            setTempYOE(cleaner.listing?.cleaning_since);
                          }}
                          className="ml-2 inline-flex items-center px-2 py-1 text-xs font-medium rounded-md text-blue-700 hover:text-blue-900"
                        >
                          {isEditingYOE ? "Cancel" : "Edit"}
                        </button>
                      </dt>
                      {isEditingYOE ? (
                        <div className="mt-1 flex gap-2">
                          <select
                            value={dayjs(tempYOE).year()}
                            onChange={(e) => setTempYOE(dayjs().year(Number(e.target.value)).format("YYYY-MM-DD"))}
                            className="block w-28 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          >
                            {Array.from({ length: 25 }, (_, i) => {
                              const year = dayjs().year() - i;
                              return (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              );
                            })}
                          </select>
                          <button
                            onClick={handleSaveYOE}
                            className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
                          >
                            Save
                          </button>
                        </div>
                      ) : (
                        <dd className="mt-1 text-sm text-gray-900">
                          {cleaner.listing?.cleaning_since && (
                            <>
                              {calculateExperience(cleaner.listing.cleaning_since).startDate}
                              <span className="text-gray-500 ml-2">
                                ({calculateExperience(cleaner.listing.cleaning_since).yearsOfExperience} years of experience)
                              </span>
                            </>
                          )}
                        </dd>
                      )}
                    </div>
                    {/* <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">City Works in</dt>
                      <dd className="mt-1 text-sm text-gray-900">{cleaner.listing?.city}</dd>
                    </div> */}
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Email address</dt>
                      <dd className="mt-1 text-sm text-gray-900">{cleaner.email}</dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Cleanings completed</dt>
                      <dd className="mt-1 text-sm text-gray-900">missing info</dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Phone
                        <button
                          onClick={() => {
                            setIsEditingPhone(!isEditingPhone);
                            setTempPhone(cleaner.phoneNumber);
                          }}
                          className="ml-2 inline-flex items-center px-2 py-1 text-xs font-medium rounded-md text-blue-700 hover:text-blue-900"
                        >
                          {isEditingPhone ? "Cancel" : "Edit"}
                        </button>
                      </dt>
                      {isEditingPhone ? (
                        <div className="mt-1 flex gap-2">
                          <input
                            type="tel"
                            value={tempPhone}
                            onChange={(e) => setTempPhone(formatPhoneNumber(e.target.value))}
                            className="block w-32 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            placeholder="(123) 456-7890"
                          />
                          <button
                            onClick={handleSavePhone}
                            className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            Save
                          </button>
                        </div>
                      ) : (
                        <dd className="mt-1 text-sm text-gray-900">{formatPhoneNumber(cleaner.phoneNumber)}</dd>
                      )}
                    </div>
                    <div className="sm:col-span-2">
                      <dt className="text-sm font-medium text-gray-500 mb-2">Selected Tags</dt>
                      <dd className="mt-1 flex flex-wrap gap-2">
                        {selectedTags.map((tag) => (
                          <span
                            key={tag.id}
                            className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-sm font-medium text-green-700 ring-1 ring-inset ring-green-700/10 group"
                          >
                            {tag.name}
                            {tag.description && (
                              <span className="ml-1 text-green-400 cursor-help" title={tag.description}>
                                ⓘ
                              </span>
                            )}
                            <button
                              onClick={() => handleRemoveTag(tag.id)}
                              className="ml-1 text-green-400 hover:text-green-600"
                              title="Remove tag"
                            >
                              ×
                            </button>
                          </span>
                        ))}
                        {selectedTags.length === 0 && (
                          <span className="text-sm text-gray-500 italic">No tags selected</span>
                        )}
                      </dd>

                      <dt className="text-sm font-medium text-gray-500 mt-4 mb-2">Available Tags</dt>
                      <dd className="mt-1 flex flex-wrap gap-2">
                        {tags
                          .filter(tag => !selectedTags.some(selected => selected.id === tag.id))
                          .map((tag) => (
                            <span
                              key={tag.id}
                              className="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-sm font-medium text-black ring-1 ring-inset ring-gray-700/10 cursor-pointer hover:bg-gray-200"
                              onClick={() => handleAddTag(tag.id)}
                              title="Click to add tag"
                            >
                              {tag.name}
                              {tag.description && (
                                <span className="ml-1 text-blue-400 cursor-help" title={tag.description}>
                                  ⓘ
                                </span>
                              )}
                            </span>
                          ))}
                        {tags.length === 0 && (
                          <span className="text-sm text-gray-500 italic">No tags available</span>
                        )}
                      </dd>
                    </div>
                    <div className="sm:col-span-2">
                      <dt className="text-sm font-medium text-gray-500">
                        About
                        <button
                          onClick={() => setIsEditingDescription(!isEditingDescription)}
                          className="ml-2 inline-flex items-center px-2 py-1 text-xs font-medium rounded-md text-blue-700 hover:text-blue-900"
                        >
                          {isEditingDescription ? "Cancel" : "Edit"}
                        </button>
                      </dt>

                      {isEditingDescription ? (
                        <div className="mt-2">
                          <QuillEditor
                            initialDelta={cleaner.listing?.description_delta}
                            initialContent={cleaner.listing?.description}
                            onSave={(content) => handleSaveDescription(content)}
                          />
                        </div>
                      ) : (
                        <dd
                          className="mt-1 text-sm text-gray-900 ql-editor"
                          dangerouslySetInnerHTML={{ __html: cleaner.listing?.description_html || cleaner.listing?.description || "" }}
                        />
                      )}
                    </div>
                    <div className="sm:col-span-2">
                      <p className="text-sm font-medium text-gray-800">Combined Ratings</p>
                      {editReviews
                        ? <div>
                        </div>
                        : <>
                          <div className={"pb-6"}>
                            <CleanerReviewCard
                              reviewItems={[
                                { title: 'Quality', value: cleaner.listing?.ratings?.quality || 4 },
                                { title: 'Reliability', value: cleaner.listing?.ratings?.reliability || 4 },
                                { title: 'Communication', value: cleaner.listing?.ratings?.communication || 4 },
                              ]}
                            />
                          </div>
                        </>
                      }
                    </div>
                    <div className="sm:col-span-2">
                      <p className="text-base font-medium text-red-600">Number of
                        complaints: {cleaner.listing?.complaints_count || 0}</p>
                      <p className="text-base font-medium text-green-500">Number of
                        reviews: {cleaner.listing?.reviews?.length || 0} </p>
                      <p
                        className="text-sm font-medium text-blue-400 hover:cursor-pointer hover:underline hover:text-blue-600"
                        onClick={() => reviewsRef.current.scrollIntoView({ behavior: 'smooth' })}>(view reviews)</p>
                    </div>
                  </dl>
                </div>
              </div>
            </section>

            <section aria-labelledby="notes-title">
              <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                <div className="divide-y divide-gray-200">
                  <div className="px-4 py-5 sm:px-6">
                    <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                      Map
                    </h2>
                  </div>
                  <div className="h-96 px-4 py-6 sm:px-6" style={{ marginBottom: '40px' }}>
                    <CleanerMap
                      north={cleaner.listing?.coordinates.north}
                      south={cleaner.listing?.coordinates.south}
                      east={cleaner.listing?.coordinates.east}
                      west={cleaner.listing?.coordinates.west}
                      onSave={(newCoords) => {
                        updateCleanerMap(newCoords)
                      }}
                    />
                  </div>
                  <AreaMapPicker />

                </div>
              </div>
            </section>

            <section aria-labelledby="notes-title" ref={reviewsRef}>
              <div className="bg-white shadow sm:rounded-lg overflow-hidden">
                <div className="divide-y divide-gray-200">
                  <div className="px-4 py-5 sm:px-6">
                    <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                      Reviews
                    </h2>
                  </div>
                  <div className="px-4 py-6 sm:px-6" style={{ marginBottom: '40px' }}>

                    <ul role="list" className="divide-y divide-gray-100">
                      {reviewsPage && getPaginatedReviews(cleaner.listing?.reviews || [], reviewsPage).map((review, idx) => (
                        <li
                          key={`review-${idx}`}
                          className="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 py-5 sm:flex-nowrap"
                        >
                          <div>
                            <div className="flex flex-row justify-between">
                              <p className="text-sm font-semibold leading-6 text-gray-900">
                                {review.text}
                              </p>


                            </div>

                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">

                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>

                    <nav
                      className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                      aria-label="Pagination"
                    >
                      <div className="hidden sm:block">
                        <p className="text-sm text-gray-700">
                          <span className="font-medium">{cleaner.listing?.reviews?.length || 0}</span> results
                        </p>
                      </div>
                      <div className="flex flex-1 justify-between sm:justify-end">
                        <p
                          className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900"
                        >{reviewsPage}</p>
                        <a
                          href="#"
                          className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                          onClick={() => {
                            if (reviewsPage > 1) {
                              setReviewsPage(reviewsPage - 1);
                            }
                          }}
                        >
                          Previous
                        </a>
                        <a
                          href="#"
                          className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                          onClick={() => {
                            if (reviewsPage < Math.ceil((cleaner.listing?.reviews?.length || 0) / 10)) {
                              setReviewsPage(reviewsPage + 1);
                            }
                          }}
                        >
                          Next
                        </a>
                      </div>
                    </nav>
                  </div>

                </div>

              </div>
            </section>
          </div>
        </div>
      </main>
    </div>
  )
}