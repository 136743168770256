import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { get } from "../../helper/fetch";

// Define the Booking type
type Booking = {
  id: number;
  clientId: string;
  cleanerId: string;
  clientName: string;
  date: string;
  times: string;
  cleaningTime: string;
  address: string;
  apartmentNumber: string | null;
  amount: number;
  coupon: {
    code: string | null;
  };
  status: string;
  recurrance: string;
};

export default function Bookings2() {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState("")
  const [statusFilter, setStatusFilter] = useState("all")
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const numberPerPage = 7;
  const [dateFilter, setDateFilter] = useState<"today" | "next7days" | null>(null);

  useEffect(() => {
    fetchBookings(1, 8, '', 'all', '');
  }, []);

  const fetchBookings = (page = 1,
    numPerPage = 8,
    searchText = '',
    status = null,
    dateFilterParam = null) => {
    setIsLoading(true);
    let dateFilterParams = "";

    if (dateFilterParam === "today") {
      const today = dayjs().format("YYYY-MM-DD");
      dateFilterParams = `&dateFrom=${today}&dateTo=${today}`;
    } else if (dateFilterParam === "next7days") {
      const today = dayjs().format("YYYY-MM-DD");
      const sevenDaysLater = dayjs().add(7, "day").format("YYYY-MM-DD");
      dateFilterParams = `&dateFrom=${today}&dateTo=${sevenDaysLater}`;
    }
    get<Booking[]>(`/bookings?pageNumber=${page - 1}&numberPerPage=${numPerPage}&status=${status}&name=${searchText}${dateFilterParams}`)
      .then((response) => {
        setBookings(response.data);
        setIsLoading(false);
      })
      .catch((e) => {
        setError("Could not fetch bookings");
        setIsLoading(false);
      });
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      const nextPage = currentPage - 1;
      fetchBookings(nextPage, 8, searchTerm, statusFilter, dateFilter);
    }
  };

  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    setCurrentPage((prevPage) => prevPage + 1);
    fetchBookings(nextPage, 8, searchTerm, statusFilter, dateFilter);
  };

  const handleDateFilter = (filter: "today" | "next7days" | null) => {
    setDateFilter(filter);
    setCurrentPage(1);
    fetchBookings(1, 8, searchTerm, statusFilter, filter);
  };

  return (
    <div className="container mx-auto p-4 space-y-6 bg-gray-100">
      <h1 className="text-2xl font-bold mb-6">Cleaner Bookings</h1>

      <div className="flex flex-col sm:flex-row gap-4">
        <div className="relative flex-grow">

          <input
            type="text"
            placeholder="Search by client name"
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-lime-500"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (e.target.value.length >= 3) {
                fetchBookings(1, 8, e.target.value, statusFilter, dateFilter);
              }
              else if (e.target.value.length === 0) {
                fetchBookings(1, 8, e.target.value, statusFilter, dateFilter);
              }
            }}
          />
        </div>

        <div className="flex gap-2">
          <button
            className={`px-4 py-2 rounded-md ${dateFilter === "today"
              ? "bg-lime-600 text-white"
              : "bg-white text-lime-600 border border-lime-600"
              }`}
            onClick={() => handleDateFilter("today")}
          >
            Today's Bookings
          </button>
          <button
            className={`px-4 py-2 rounded-md ${dateFilter === "next7days"
              ? "bg-lime-600 text-white"
              : "bg-white text-lime-600 border border-lime-600"
              }`}
            onClick={() => handleDateFilter("next7days")}
          >
            Next 7 Days
          </button>
          {dateFilter && (
            <button
              className="px-4 py-2 rounded-md bg-gray-200 text-gray-700"
              onClick={() => handleDateFilter(null)}
            >
              Clear Date Filter
            </button>
          )}
        </div>
      </div>

      {/* Pagination controls */}
      <div className="flex justify-between items-center mt-4">
        <button
          className="px-4 py-2 bg-lime-500 text-white rounded-md disabled:bg-gray-300"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>
          Page {currentPage}
        </span>
        <button
          className="px-4 py-2 bg-lime-500 text-white rounded-md disabled:bg-gray-300"
          onClick={handleNextPage}
          disabled={bookings?.length < numberPerPage}
        >
          Next
        </button>
      </div>

      <div className="space-y-4">
        {bookings && bookings.map(booking => (
          <div
            key={booking.id}
            className="relative bg-white shadow rounded-lg overflow-hidden group hover:shadow-lg transition-shadow duration-200 cursor-pointer"
            onClick={() => history.push('/bookings/' + booking.id)}
          >
            <div className="absolute inset-0 rounded-lg border-2 border-transparent group-hover:border-lime-600 transition-colors duration-200 pointer-events-none"></div>
            <div className="relative p-4 flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-2 sm:space-y-0">
              <div className="space-y-1">
                <p className="font-medium">#{booking.id}</p>
                <p className="font-medium">with {booking.clientName}</p>
                <p className="font-medium">{dayjs(booking.date).format('YYYY, MMM DD')} at {booking.cleaningTime}</p>
                <p className="text-sm text-gray-600">{booking.address}</p>
              </div>
              <div className="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-4">
                <span className={`text-sm px-2 py-1 rounded-full ${getStatusColor(booking.status)}`}>
                  {booking.status}
                </span>
                <span className="font-medium">${booking.amount}</span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination controls */}
      <div className="flex justify-between items-center mt-4">
        <button
          className="px-4 py-2 bg-lime-500 text-white rounded-md disabled:bg-gray-300"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>
          Page {currentPage}
        </span>
        <button
          className="px-4 py-2 bg-lime-500 text-white rounded-md disabled:bg-gray-300"
          onClick={handleNextPage}
          disabled={bookings?.length < numberPerPage}
        >
          Next
        </button>
      </div>

      {/* Loading Spinner Overlay */}
      {isLoading && (
        <div className="absolute inset-0 bg-gray-200 bg-opacity-50 flex items-center justify-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      )}

      {/* Error Message */}
      {error && (
        <div className="text-red-500 text-center mt-4">
          {error}
        </div>
      )}
    </div>
  )
}

function getStatusColor(status: string) {
  switch (status) {
    case 'requested':
      return 'bg-yellow-100 text-yellow-800'
    case 'accepted':
      return 'bg-green-100 text-green-800'
    case 'rejected':
      return 'bg-red-100 text-red-800'
    case 'completed':
      return 'bg-blue-100 text-blue-800'
    default:
      return 'bg-gray-100 text-gray-800'
  }
}
