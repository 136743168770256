import fetch from "isomorphic-unfetch";
import { getCookie } from "./session";

// Define strict types for API responses and requests
type ApiResponse<T> = {
	data: T;
	error?: string;
	status: number;
};

type RequestOptions = {
	authHeader?: string;
	skipAuth?: boolean;
};

type HttpMethod = "GET" | "POST" | "PUT" | "DELETE";

const BASE_URL = process.env.REACT_APP_homespritzAPI || "http://127.0.0.1:5006";

/**
 * Tracks the last redirect timestamp to prevent excessive redirects
 */
let lastRedirectTimestamp = 0;
const REDIRECT_COOLDOWN_MS = 5000; // 5 seconds

/**
 * Creates common headers for all API requests
 * @param authHeader - Optional override for auth token
 * @param skipAuth - Optional flag to skip authentication
 */
const createHeaders = (authHeader?: string, skipAuth?: boolean): HeadersInit => {
	const headers: HeadersInit = {
		"Accept": "application/json",
		"Content-Type": "application/json",
		"Cache": "no-cache",
	};

	if (!skipAuth) {
		headers["Authorization"] = authHeader || getCookie("access_token");
	}

	return headers;
};

/**
 * Generic error handler for API requests
 * @param response - Fetch Response object
 */
const handleResponse = async<T>(response: Response): Promise<ApiResponse<T>> => {
	if (!response.ok) {
		// Handle specific HTTP errors
		if (response.status === 401) {
			// Store current path for post-login redirect
			const currentPath = window.location.pathname;
			sessionStorage.setItem("redirectAfterLogin", currentPath);

			// Only redirect if not already on login page and respecting rate limit
			if (window.location.pathname !== "/login" && window.location.pathname !== "/") {
				redirectToLogin();
			}
			return Promise.reject(new Error("Unauthorized"));
		}

		const errorData = await response.json().catch(() => ({}));
		throw new Error(errorData.message || "Request failed");
	}

	const data = await response.json().catch(() => ({}));
	return {
		data,
		status: response.status,
	};
};

/**
 * Generic request function that handles all HTTP methods
 * @param method - HTTP method
 * @param url - API endpoint
 * @param data - Request body (optional)
 * @param options - Additional request options
 */
const request = async <T>(
	method: HttpMethod,
	url: string,
	data?: unknown,
	options: RequestOptions = {}
): Promise<ApiResponse<T>> => {
	try {
		const response = await fetch(`${BASE_URL}${url}`, {
			method,
			credentials: "include",
			headers: createHeaders(options.authHeader, options.skipAuth),
			...(data && { body: JSON.stringify(data) }),
		});

		return handleResponse<T>(response);
	} catch (error) {
		// Log error for debugging
		console.error(`API ${method} request failed:`, error);
		throw error;
	}
};

/**
 * Redirects to login page with optional return URL, rate limited to once every 5 seconds
 * @returns boolean indicating if redirect was performed
 */
const redirectToLogin = (): boolean => {
	const now = Date.now();
	if (now - lastRedirectTimestamp < REDIRECT_COOLDOWN_MS) {
		return false;
	}

	// Store current path for post-login redirect
	const currentPath = window.location.pathname;
	sessionStorage.setItem("redirectAfterLogin", currentPath);

	// Only redirect if not already on login page
	if (window.location.pathname !== "/login") {
		lastRedirectTimestamp = now;
		window.location.href = "/login";
		return true;
	}
	return false;
};

// Exported API methods with proper typing
export const get = <T>(url: string, options?: RequestOptions) =>
	request<T>("GET", url, undefined, options);

export const post = <T>(url: string, data: unknown, options?: RequestOptions) =>
	request<T>("POST", url, data, options);

export const put = <T>(url: string, data: unknown, options?: RequestOptions) =>
	request<T>("PUT", url, data, options);

export const del = <T>(url: string, options?: RequestOptions) =>
	request<T>("DELETE", url, undefined, options);

export const getPURE = (url: string, authHeader?: string): Promise<any> => {
	return fetch(BASE_URL + url, {
		method: "GET",
		credentials: 'include',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Cache': 'no-cache',
			'Authorization': authHeader || getCookie('access_token')
		}
	})
		.then(result => {
			if (result.status === 401) {
				redirectToLogin();
				return;
			}
			if (result) {
				return result.json();
			} else {
				return {};
			}
		})
		.catch(e => {
			throw e
		});
};

export const postPURE = (url: string, data: any, authHeader?: string): Promise<Response> => {
	return fetch(BASE_URL + url, {
		method: "POST",
		credentials: 'include',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Cache': 'no-cache',
			'Authorization': authHeader || getCookie('access_token')
		},
		body: JSON.stringify(data),
	});
};

export const putPURE = (url: string, data: any, authHeader?: string): Promise<Response> => {
	return fetch(BASE_URL + url, {
		method: "PUT",
		credentials: 'include',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Cache': 'no-cache',
			'Authorization': authHeader || getCookie('access_token')
		},
		body: (data) ? JSON.stringify(data) : '',
	});
};

export const delPURE = (url: string, authHeader?: string): Promise<Response> => {
	return fetch(BASE_URL + url, {
		method: "DELETE",
		credentials: 'include',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Cache': 'no-cache',
			'Authorization': authHeader || getCookie('access_token')
		}
	});
};

