import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { GoogleMapsWrapper } from "./BookingStyle";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { get, post } from "../../helper/fetch";
import { toast } from 'react-toastify';
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import './BookingStyle.css';
import BookingStatus from "../../components/BookingStatus/BookingStatus";
import Chat, { Message } from "../../components/Chat/Chat";
import CleanerCheckList from "../../components/CleanerCheckList/CleanerCheckList";
import {
  airbnbCleanChecklist,
  CheckListProps,
  generalCleanChecklist,
  moveOutCleanChecklist
} from "../../settings/bookingTypes";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import { ClockIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/outline';

dayjs.extend(isSameOrBefore);

type BookingExtra = {
  name: string;
  id: number;
  price?: number;
}

type BookingDetails = {
  id: number;
  listingId?: number;
  clientName: string;
  date: Date;
  currentState: string;
  cleaningTime: string;
  recurrance?: string,
  canExtendHours?: boolean,

  totalAmount: number,
  coupon?: {
    percentage: number;
    amount: number;
    code: number;
  },
  extras: BookingExtra[],
  cleaningSupplies: number,
  address: string;
  apartmentNumber: string;
  entranceInfo: string;
  parkingInfo: string;
  otherInfo: string;
  numberOfRooms?: number;
  numberOfBathrooms?: number;
  squareFootage?: number;
  sizeIncludesBasement?: boolean;

  coordinates: {
    lat: number,
    lng: number
  },

  messagesList: Message[];
  messages: [];
  conversationId: number;
  startedAt: string;
  stoppedAt: string;
  startHour: number;
  startMinute: number;
  endHour: number;
  endMinute: number;
}
export type BookingStatus = "requested" | "accepted" | "cancelled" | "canceled" | "complete";
type StatusColors = {
  [key in BookingStatus]: string;
};

export const statusColors: StatusColors = {
  requested: "bg-yellow-500",
  accepted: "bg-lime-500",
  cancelled: "bg-red-500",
  canceled: "bg-red-500",
  complete: "bg-indigo-500",
};
// Move StatusIcon outside of Bookings component
const StatusIcon: React.FC<{ status: BookingStatus }> = ({ status }) => {
  switch (status) {
    case "requested":
      return <ClockIcon className="h-4 w-4" />;
    case "accepted":
      return <CheckCircleIcon className="h-4 w-4" />;
    case "cancelled":
    case "canceled":
      return <XCircleIcon className="h-4 w-4" />;
    case "complete":
      return <CheckCircleIcon className="h-4 w-4" />;
    default:
      return null;
  }
};
const Booking: React.FC<any> = () => {
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    openBookingDetailDrawer(id);
  }, []);

  const openBookingDetailDrawer = async (bookingId) => {
    try {
      const bookingDetails = await get<BookingDetails>('/bookings/' + bookingId);
      setBookingDetails(bookingDetails.data);
      setMessagesList(bookingDetails.data?.messages);
      const extras = await get<{ extras: BookingExtra[] }>('/extras');
      let extraSet = new Set<BookingExtra>();
      for (let e of extras.data?.extras) {
        extraSet.add(e);
      }
      setExtras(extraSet);
      //populate newExtras here
      let newextraSet = new Set<BookingExtra>();
      for (let e of bookingDetails.data?.extras) {
        newextraSet.add(e);
      }
      setExtrasReady(true);
    } catch (e) {
      //TODO: do this shit
    }
  }

  const [bookingDetails, setBookingDetails] = useState<BookingDetails>(null);
  const [messageLoading, setMessageLoading] = useState(false);
  const [messagesList, setMessagesList] = useState<Message[]>([] as Message[]);
  const [newMessage, setNewMessage] = useState('');
  const [confirmStartModalOpen, setConfirmStartModalOpen] = useState(false);
  const [confirmRejectModalOpen, setConfirmRejectModalOpen] = useState(false);
  const [extras, setExtras] = useState(new Set<BookingExtra>());
  const [extrasReady, setExtrasReady] = useState(false);
  const [isPropertyDetailsOpen, setIsPropertyDetailsOpen] = useState(true);
  const [isBookingDetailsOpen, setIsBookingDetailsOpen] = useState(true);


  const submitNewMessageToBooking = async (newMessage, conversationId) => {
    //TODO: nearly guaranteed to be total garbage. Need to use reducer instead of useState
    setMessageLoading(true);
    setNewMessage('');
    try {
      const res = await submitNewMessage(newMessage, conversationId);
      if (res) {
        const msg: Message = {
          createdAt: new Date(),
          sender: {
            role: 'cleaner', name: ''
          },
          content: newMessage
        };
        setMessagesList(messagesList => {
          return [...messagesList, msg]
        });
      }
    } catch (e) {
      //TODO: error msg please
      console.log(e);
    }
    setMessageLoading(false)
  };

  const submitNewMessage = async (newMessage, conversationId) => {
    try {
      const res = await post('/bookings/conversations/' + conversationId, {
        newMessage: newMessage,
        bookingId: bookingDetails.id
      });
      if (res.status !== 201) {
        toast.error('Could not add message');
        return false;
      }
      toast.success('Added message');
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }

  };

  const addNotification = async (bookingId) => {
    try {
      const resp = await post(`/notifications`, { booking_id: bookingId });
      if (resp.status !== 201) {
        toast.error('Could not notify admins about this booking');
      }
      toast.success('Notified admins about this booking!');
    } catch (e) {
      toast.error('Could not notify admins about this booking');
    }
  }

  const startCleaning = async () => {
    try {
      const res = await post(`/bookings/${bookingDetails.id}/start`, { when: dayjs().format('YYYY MM DD h:mm A') });
      if (res.status !== 200) {
        toast.error('Oops! Could not add START booking contact administrators, but do proceed with the cleaning we\'ll sort it out');
        return false;
      }
      toast.success('Started!');
      return true;
    } catch (e) {
      toast.error('Could not add START booking contact administrators ASAP');
      return false;
    }
  };

  const stopCleaning = async () => {
    try {
      const res = await post(`/bookings/${bookingDetails.id}/stop`, { when: dayjs().format('YYYY MM DD h:mm A') });
      if (res.status !== 200) {
        toast.error('Oops! Could not add STOP booking contact administrators, we\'ll sort it out');
        return false;
      }
      toast.success('Stopped!');
      // setStopped(true);
      return true;
    } catch (e) {
      toast.error('Oops! Could not add STOP booking contact administrators, we\'ll sort it out');
      return false;
    }
  };

  const acceptCleaningRequest = async () => {
    get<BookingDetails>(`/bookings/${bookingDetails.id}/accept`)
      .then(res => {
        if (res.status !== 200) {
          toast.error('Could not add ACCEPT booking contact administrators ASAP');
          return false;
        }
        toast.success('ACCEPTED booking request');
        setBookingDetails({ ...bookingDetails, currentState: 'accepted' })
        return true;
      })
      .catch(e => {
        console.log(e);
        toast.error('Could not add ACCEPT booking contact administrators ASAP');
        return false;
      });
  };

  const rejectCleaningRequest = async () => {
    get<BookingDetails>(`/bookings/${bookingDetails.id}/reject`)
      .then(res => {
        if (res.status !== 200) {
          toast.error('Could not add REJECT booking contact administrators ASAP');
          return false;
        }
        toast.success('REJECTED booking request');
        setBookingDetails({ ...bookingDetails, currentState: 'rejected' })
        return true;
      })
      .catch(e => {
        toast.error('Could not add REJECT booking contact administrators ASAP');
        return false;
      });
  };

  function couponCovers() {
    if (bookingDetails.coupon && bookingDetails.coupon.percentage) {
      return `(${bookingDetails.coupon.percentage}% coupon was used by the client)`;
    }
  }

  function generateCheclist() {
    let checklist: CheckListProps[] = [];
    //itterate over booking.extras
    //if extra is a checklist item, add it to checklist
    for (const extra of bookingDetails.extras) {
      if (extra.id === 12) {
        checklist = moveOutCleanChecklist as CheckListProps[];
      } else if (extra.id === 199 || extra.id === 200) {
        checklist = airbnbCleanChecklist as CheckListProps[];
      } else {
        checklist = generalCleanChecklist as CheckListProps[];
      }
    }
    return <ErrorBoundary
      fallback={<h1>Sorry, there was an error, we can't show you cleaning checklist atthis time</h1>}>
      <CleanerCheckList
        checklist={checklist}
      />
    </ErrorBoundary>

  }

  return (
    <>
      {bookingDetails && (
        <>
          <Transition show={confirmStartModalOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-50"
              onClose={() => setConfirmStartModalOpen(false)}
            >
              {/* Backdrop */}
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
              </Transition.Child>

              {/* Full-screen container to center the panel */}
              <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900 mb-4"
                    >
                      Start your Cleaning
                    </Dialog.Title>

                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        This action will notify client that you've started the cleaning. Proceed?
                      </p>
                    </div>

                    <div className="mt-6 flex justify-end gap-3">
                      <button
                        type="button"
                        className="rounded-md bg-gray-200 px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200"
                        onClick={() => setConfirmStartModalOpen(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="rounded-md bg-lime-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600"
                        onClick={async (e) => {
                          e.preventDefault();
                          await startCleaning();
                          setConfirmStartModalOpen(false);
                          window.location.reload();
                        }}
                      >
                        Send
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition>

          <Transition show={confirmRejectModalOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-50"
              onClose={() => setConfirmRejectModalOpen(false)}
            >
              {/* Backdrop */}
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
              </Transition.Child>

              {/* Full-screen container to center the panel */}
              <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900 mb-4"
                    >
                      Reject booking request
                    </Dialog.Title>

                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to reject this cleaning request?
                      </p>
                    </div>

                    <div className="mt-6 flex justify-end gap-3">
                      <button
                        type="button"
                        className="rounded-md bg-gray-200 px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200"
                        onClick={() => setConfirmRejectModalOpen(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="rounded-md bg-red-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                        onClick={async (e) => {
                          e.preventDefault();
                          await rejectCleaningRequest();
                          setConfirmRejectModalOpen(false);
                        }}
                      >
                        Reject
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition>

          {/*MAP*/}
          <div className="mt-8 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:p-6">
              <GoogleMapsWrapper>
                <LoadScript
                  id="script-loader"
                  googleMapsApiKey="AIzaSyCm5ChnsE7FPArmt7EiHsGcp99Z7EqbPTM"
                >
                  <GoogleMap
                    id='cleaner-workin-map'
                    mapContainerStyle={{
                      height: "100%",
                      width: "100%"
                    }}
                    zoom={11}
                    center={{ lat: bookingDetails.coordinates.lat || 0, lng: bookingDetails.coordinates.lng || 0 }}
                    options={
                      {
                        fullscreenControl: true,
                        mapTypeControl: false,
                        streetViewControl: false
                      }
                    }

                  >
                    <Marker position={{
                      lat: bookingDetails.coordinates.lat || 0,
                      lng: bookingDetails.coordinates.lng || 0
                    }} />
                  </GoogleMap>
                </LoadScript>
              </GoogleMapsWrapper>
            </div>
          </div>

          <div className='py-4 pt-6 px-4 sm:px-0 mb-6'>
            <h1 className='text-xl font-semibold text-gray-900'>Booking Id: {bookingDetails.id}</h1>
          </div>

          <div className="m-2">
            {/* <button
              className="px-4 py-2 bg-yellow-600 rounded-md text-white hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50 transition-colors"
              onClick={e => {
                e.preventDefault();
                addNotification(bookingDetails.id);
              }}
            >Poke admins about this booking
            </button> */}
          </div>
          {/* Booking Details Section */}
          <div className="pt-8 border-t border-gray-200">
            <div className="px-4 sm:px-0 mb-6">
              <h2 className="text-lg font-semibold text-gray-900">Booking Information</h2>
              <div id={'start-stop-wrapper'} className='p-2'>
                {bookingDetails.currentState !== 'cancelled' && bookingDetails.currentState !== 'rejected' && !bookingDetails.startedAt &&
                  <button
                    type='button'
                    className="mt-4 mb-4 w-auto rounded-md bg-lime-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600"
                    onClick={(e) => {
                      e.preventDefault();
                      setConfirmStartModalOpen(true);
                    }}
                  >
                    Start Cleaning
                  </button>
                }

                {(bookingDetails.startedAt && !bookingDetails.stoppedAt) &&
                  <button
                    type='button'
                    className="mt-4 mb-4 w-auto rounded-md bg-lime-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600"
                    onClick={async (e) => {
                      e.preventDefault();
                      await stopCleaning();
                      window.location.reload();
                    }}
                  >
                    Stop Cleaning
                  </button>
                }
              </div>
            </div>

            {/* Booking Details Card */}
            <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
              <button
                className="w-full px-4 py-5 sm:px-6 text-xl flex justify-between items-center hover:bg-gray-50"
                onClick={() => setIsBookingDetailsOpen(!isBookingDetailsOpen)}
              >
                <div className="px-4 sm:px-0">
                  <h3 className="text-base/7 font-semibold text-gray-900">Booking Details</h3>
                  <p className="mt-1 max-w-2xl text-sm/6 text-gray-500">Information about the booking schedule and status.</p>
                </div>
                <svg
                  className={`w-5 h-5 transition-transform duration-200 ${isBookingDetailsOpen ? "rotate-180" : ""}`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              <div
                className={`transition-all duration-200 ease-in-out overflow-hidden ${isBookingDetailsOpen ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"
                  }`}
              >
                <div className="mt-6 border-t border-gray-200">
                  <dl className="divide-y divide-gray-100">
                    <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                      <dt className="text-sm/6 font-medium text-gray-900">Date and time</dt>
                      <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{dayjs(bookingDetails.date).format('DD MMM')}, {bookingDetails.cleaningTime}</dd>
                      <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">For {bookingDetails.clientName}, {bookingDetails.recurrance || 'one time booking'}</dd>
                      {bookingDetails.startedAt && <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">Started at {dayjs(bookingDetails.startedAt).format('DD MMM HH:mm')}</dd>}
                      {bookingDetails.stoppedAt && <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">Stopped at {dayjs(bookingDetails.stoppedAt).format('DD MMM HH:mm')}</dd>}
                    </div>
                    <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                      <dt className="text-sm/6 font-medium text-gray-900">Status</dt>
                      <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <span className={`px-3 py-1 rounded-full text-white text-sm flex items-center ${statusColors[bookingDetails.currentState as BookingStatus]}`}>
                          <StatusIcon status={bookingDetails.currentState as BookingStatus} />
                          <span className="ml-1 capitalize">{bookingDetails.currentState}</span>
                        </span>
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                      <dt className="text-sm/6 font-medium text-gray-900">Extras</dt>
                      <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{bookingDetails.extras.map(extra => extra.name).join(', ')}</dd>
                    </div>
                    <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                      <dt className="text-sm/6 font-medium text-gray-900">Amount</dt>
                      <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">${bookingDetails.totalAmount}</dd>
                    </div>
                  </dl>
                </div>

                {(bookingDetails.currentState === 'requested' && (dayjs().isSameOrBefore(dayjs(bookingDetails.date), 'date'))) && (
                  <div id={'accept-reject-wrapper'}>
                    <div className="px-6 py-4 flex justify-between">
                      <button
                        className="w-[45%] py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-opacity-50 transition-colors"
                        onClick={() => setConfirmRejectModalOpen(true)}
                      >
                        Reject
                      </button>
                      <button
                        className="w-[45%] py-2 bg-lime-600 rounded-md text-white hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-opacity-50 transition-colors"
                        onClick={acceptCleaningRequest}
                      >
                        Accept
                      </button>
                    </div>
                  </div>
                )}
              </div>

            </div>

          </div>


          {/* Property Details Section */}
          <div className="pt-8 border-t border-gray-200 mt-8">
            <div className="px-4 sm:px-0 mb-6">
              <h2 className="text-lg font-semibold text-gray-900">Property Information</h2>
            </div>

            {/* Property Details Card */}
            <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
              <button
                className="w-full px-4 py-5 sm:px-6 text-xl flex justify-between items-center hover:bg-gray-50"
                onClick={() => setIsPropertyDetailsOpen(!isPropertyDetailsOpen)}
              >
                <div className="px-4 sm:px-0">
                  <h3 className="text-base/7 font-semibold text-gray-900">Property Details</h3>
                  <p className="mt-1 max-w-2xl text-sm/6 text-gray-500">Information about the property location and specifications.</p>
                </div>
                <svg
                  className={`w-5 h-5 transition-transform duration-200 ${isPropertyDetailsOpen ? "rotate-180" : ""}`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              <div
                className={`transition-all duration-200 ease-in-out overflow-hidden ${isPropertyDetailsOpen ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"
                  }`}
              >
                <div className="mt-6 border-t border-gray-100">
                  <dl className="divide-y divide-gray-100">
                    <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                      <dt className="text-sm/6 font-medium text-gray-900">Address</dt>
                      <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {bookingDetails.apartmentNumber && `#${bookingDetails.apartmentNumber}, `}
                        {bookingDetails.address}
                      </dd>
                    </div>
                    <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                      <dt className="text-sm/6 font-medium text-gray-900">Entrance info</dt>
                      <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{bookingDetails.entranceInfo || "missing"}</dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                      <dt className="text-sm/6 font-medium text-gray-900">Parking info</dt>
                      <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{bookingDetails.parkingInfo || "missing"}</dd>
                    </div>
                    <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                      <dt className="text-sm/6 font-medium text-gray-900">Number of rooms</dt>
                      <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{bookingDetails.numberOfRooms || "missing"}</dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                      <dt className="text-sm/6 font-medium text-gray-900">Number of bathrooms</dt>
                      <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{bookingDetails.numberOfBathrooms || "missing"}</dd>
                    </div>
                    <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                      <dt className="text-sm/6 font-medium text-gray-900">Square footage</dt>
                      <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{bookingDetails.squareFootage || "missing"}</dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                      <dt className="text-sm/6 font-medium text-gray-900">Other info</dt>
                      <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{bookingDetails.otherInfo || "missing"}</dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>


          {/*CHAT*/}
          <div className="mt-8 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:p-6">
              <Chat
                messagesList={messagesList}
                submitNewMessageToBooking={submitNewMessageToBooking}
                conversationId={bookingDetails.conversationId}
              />
            </div>
          </div>
          {/*CHECKLIST*/}

          <div className="mt-8 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:px-6 text-xl">
              Cleaning Check List
            </div>
            <div className="px-4 py-5 sm:p-6">
              {generateCheclist()}
            </div>
          </div>

        </>
      )
      }
    </>
  );
};

export default Booking;
