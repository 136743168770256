import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ClockIcon, LocationMarkerIcon, CurrencyDollarIcon, LightningBoltIcon } from "@heroicons/react/outline";
import { get } from "../../helper/fetch";

export type BookingDetails = {
  cleanerName: string;
  address: string;
  price: number;
  extras: { name: string; id: number | string }[];
  hours: number;
  id: string; // Assuming there's an id field for routing
  created_at: string; // Assuming there's a created_at field
  count: number;
  isDirect?: boolean;
  cleaningType?: string;
  recurrence?: string;
};

export default function BookingOfferList() {
  const [offers, setOffers] = useState<BookingDetails[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        setIsLoading(true);
        const response = await get<BookingDetails[]>("/offers");
        if ('error' in response) {
          setError(response.error);
        } else {
          setOffers(response.data);
          setError(null);
        }
      } catch (err) {
        setError("Failed to fetch offers. Please try again later.");
        console.error("Error fetching offers:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOffers();
  }, []);

  if (isLoading) {
    return <div className="min-h-screen bg-gray-100 flex items-center justify-center">Loading...</div>;
  }

  if (error) {
    return <div className="min-h-screen bg-gray-100 flex items-center justify-center text-red-600">{error}</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-2xl font-bold mb-6">Booking Offers</h1>
        {(offers === null || offers === undefined || offers.length === 0) ? (
          <div className="text-center py-8 bg-white rounded-lg shadow">
            <p className="text-xl text-gray-600">No offers available at this time</p>
          </div>
        ) : (
          <div className="space-y-4">
            {offers && offers.map((offer) => (
              <Link
                key={offer.id}
                to={`/o/${offer.id}`}
                className="block bg-white shadow-md rounded-lg overflow-hidden hover:shadow-lg transition-shadow"
              >
                <div className="p-6">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-semibold">Offer for {offer.cleanerName}</h2>
                    <span className="text-sm text-gray-500">
                      {offer.count === 1
                        ? "Only you have received this offer"
                        : `${offer.count} people have also received this offer`}
                    </span>
                  </div>
                  <div className="space-y-2">
                    {offer.isDirect && (
                      <div className="flex items-center space-x-2">
                        <LightningBoltIcon className="h-5 w-5 text-yellow-500 animate-pulse" />
                        <span className="text-gray-700 font-semibold">Direct booking request from Client.</span>
                      </div>
                    )}
                    {offer.cleaningType && (
                      <div className="flex items-center space-x-2">
                        <p className=" text-gray-700"> Type: {offer.cleaningType}</p>
                      </div>
                    )}
                    {offer.recurrence && (
                      <div className="flex items-center space-x-2">
                        <p className="text-gray-700"> Recurrence: {offer.recurrence}</p>
                      </div>
                    )}
                    <div className="flex items-center space-x-2">
                      <LocationMarkerIcon className="h-5 w-5 text-gray-500" />
                      <span className="text-gray-700">{offer.address}</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <CurrencyDollarIcon className="h-5 w-5 text-gray-500" />
                      <span className="text-gray-700">${offer.price}</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <ClockIcon className="h-5 w-5 text-gray-500" />
                      <span className="text-gray-700">{offer.hours} hours</span>
                    </div>
                    <div>
                      <h3 className="font-semibold">Extras:</h3>
                      <ul className="list-disc list-inside pl-2">
                        {offer.extras.map((extra) => (
                          <li key={extra.id}>{extra.name}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
