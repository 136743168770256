import React from 'react';

import { AnnouncementWithAuthor } from './AnnouncementManager';
import dayjs from 'dayjs';

/**
 * Capitalizes the first letter of each word in a name
 * @param name - The name to capitalize
 * @returns The capitalized name
 */
const capitalizeNames = (name: string): string => {
  return name
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export default function IndividualCard({ announcement }: { announcement: AnnouncementWithAuthor }) {

  return (
    <div>
      <div className="overflow-hidden bg-white sm:rounded-lg sm:shadow" key={announcement.id}>
        <div key={announcement.id} className="bg-white px-4 py-5 sm:px-6">
          <div className="flex space-x-3">
            <div className="flex-1 flex-col">
              <div className="min-w-0 flex-1">
                <p className="text-lg font-semibold text-gray-900">
                  {announcement.title}
                </p>
                <div className="shrink-0">
                  <p className="text-sm text-gray-500">
                    Admin, {dayjs(announcement.created_at).format('MMM D, YYYY')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <dd
          className="m-4 mt-1 text-sm text-gray-900 ql-editor"
          dangerouslySetInnerHTML={{ __html: announcement.content_html || "" }}
        />
      </div>
    </div>
  );
}
